class ToggleReferralField {
  constructor({ trigger, target }) {
    this.trigger = document.querySelector(trigger);
    this.targetDiv = document.querySelector(target);
    this.attachHandler = this.attachHandler.bind(this);
  }

  apply() {
    this.attachHandler();
    this.trigger.addEventListener('change', this.attachHandler);
  }

  attachHandler() {
    if (this.trigger.value === 'Other') {
      this.targetDiv.querySelectorAll('label, input').forEach((el) => {
        el.disabled = false;
      });
    } else {
      this.targetDiv.querySelectorAll('label, input').forEach((el) => {
        if (el.tagName === 'INPUT') {
          el.value = '';
        }
        el.disabled = true;
      });
    }
  }
}

export default ToggleReferralField;
