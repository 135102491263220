class CaseStudiesListing {
  constructor({ selector, rails }) {
    this.container = document.querySelector(selector);

    if (!this.container) { return null; }

    const form = this.container.querySelector('form');
    if (!form) { return null; }

    const caseStudyCategorySelect = form.querySelector('#case-study-category-select');
    if (!caseStudyCategorySelect) { return null; }

    caseStudyCategorySelect.addEventListener('change', (e) => {
      rails.fire(form, 'submit');

      // Update/push the URL:
      const category = e.target.value;
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('category', category);
      searchParams.delete('page');
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.pushState(null, '', newUrl);
    });
  }
}

export default CaseStudiesListing;
