import Glide from '@glidejs/glide';
import Helpers from '../lib/helpers';

class LogoSlider {
  constructor({
    selector,
    logoGalleriesArray = [],
    viewportWidthThresholdArray = [],
  }) {
    this.selector = document.querySelectorAll(selector);

    // Only run if selector is on page
    if (!this.selector) { return null; }

    // Setup values for later
    this.logoGalleriesArray = logoGalleriesArray;
    this.viewportWidthThresholdArray = viewportWidthThresholdArray;
    this.vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    this.logoSliderBreakpoint = 768; // px value to re-init the slider
    this.slideAmountThreshold = 5;

    // Start our logo slider functions
    this.initSlider();
  }

  initSlider() {
    Helpers.forEach(this.selector, (el, i) => {
      const slides = el.querySelectorAll('.glide__slide');
      // Store an empty value in array for each slider so it can be updated later
      this.viewportWidthThresholdArray.push('');

      if (slides.length > this.slideAmountThreshold) {
        // console.log('> slideAmountThreshold slides. Check VW before mounting');
        this.viewportWidthChecker(el, i);
        this.resizeWindowEvents(el, i);
      } else {
        // console.log('< slideAmountThreshold slides. Slider wont init');
      }
    });
  }

  mountSlider(el, i) {
    Helpers.addClass(el, 'logo-slider--active');

    const currentLogoSlider = el;
    this.logoGalleriesArray.push(currentLogoSlider);

    this.logoGalleriesArray[i] = new Glide(el, {
      type: 'carousel',
      gap: 0,
      animationDuration: 1000,
      autoplay: 3000,
      focusAt: 'center',
      autoheight: true,
      hoverpause: false,
      swipeThreshold: false,
      dragThreshold: false,
      perView: 5,
      // breakpoints: {
      //   959: {
      //     perView: 5,
      //     autoplay: 4000,
      //   },
      // },
    });

    this.logoGalleriesArray[i].mount();
  }

  // Check width of the viewport
  /////////////////////////////////////////////////
  viewportWidthChecker(el, i) {
    // if viewport width is greater than the logoSliderBreakpoint
    if (this.vw >= this.logoSliderBreakpoint) {
      if (this.viewportWidthThresholdArray[i] !== 'higher') {
        // console.log('above the breakpoint');
        this.viewportWidthThresholdArray[i] = 'higher';
        this.carouselToggleSwitch(el, i);
      }
    } else {
      if (this.viewportWidthThresholdArray[i] !== 'lower') {
        // console.log('Below the breakpoint')
        this.viewportWidthThresholdArray[i] = 'lower';
        this.carouselToggleSwitch(el, i);
      }
    }
  }

  // Switch to decide whether to create or destroy
  /////////////////////////////////////////////////
  carouselToggleSwitch(el, i) {
    if (this.viewportWidthThresholdArray[i] === 'higher') {
      // console.log('above... play or create slider');
      // Create & mount slider
      this.mountSlider(el, i);
    } else {
      // console.log('below.. pause or destroy');
      // if gallery already created, destroy it
      // otherwise don't need to do anything as already below breakpoint
      if (this.logoGalleriesArray[i]) {
        Helpers.removeClass(el, 'logo-slider--active');
        this.logoGalleriesArray[i].destroy();
      }
    }
  }

  resizeWindowEvents(el, i) {
    // if viewport is resized check whether we need to create or destroy the slider
    window.addEventListener('resize', () => {
      this.vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      // constantly run the VW checker as we resize the window
      this.viewportWidthChecker(el, i);
    }, { passive: true }); // set event listener to passive
    // as we're not changing the default event behaviour of resize
  }
}

export default LogoSlider;
